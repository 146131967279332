import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import { ButtonLink } from '~/components/button-link';
import { ContentContainer } from '~/components/container';
import { Glyph } from '~/components/glyph';

import { ErrorContent } from '../types/error-content';

export function ErrorContentSection({ title, text, glyphName }: ErrorContent) {
  return (
    <Box
      as='section'
      padding={{
        base: '60px 0px',
        md: '120px 0px',
      }}
    >
      <ContentContainer>
        <Flex alignItems='center' flexDir='column' margin='0 auto' maxWidth='544px' textAlign='center'>
          <Glyph height={320} name={glyphName} width={320} />
          <Box marginBottom='32px'>
            <Heading as='h1' size='h1'>
              {title}
            </Heading>
            <Text>{text}</Text>
          </Box>
          <ButtonLink
            buttonProps={{
              width: {
                base: '100%',
                md: 'fit-content',
              },
            }}
            linkProps={{
              href: '/',
            }}
          >
            Return Home
          </ButtonLink>
        </Flex>
      </ContentContainer>
    </Box>
  );
}
